import useCampaigns from '@/components/Goods/Campaigns/useCampaigns'
import useOrders from '@/components/Purchase/components/Orders/useOrders'
import LoaderPage from '@/shared/Loaders/LoaderPage/LoaderPage'
import LogoLayout from '@/shared/Layouts/LogoLayout/LogoLayout'
import { useCallback, useEffect, useMemo } from 'react'
import persistenceStateKeys from '@/shared/persistenceState.keys'
import CampaignTile from '@/components/Goods/Campaigns/components/CampaignTile'
import OrderCard from '@/components/Purchase/components/Orders/components/OrderCard'
import { useRouter } from 'next/router'
import { IOrder } from '@/components/Purchase/components/Orders/orders.types'
import Slider from '@/shared/Slider'
import useSlider from '@/shared/Slider/useSlider'
import Button from '@/shared/Button/Button'
import domUtils from '@/shared/dom.utils'
import NewsProvider from '@/components/News/components/NewsProvider'
import MainNewsBlock from '@/components/News/components/MainNewsBlock'
import CampaignActionBtn from '@/components/Goods/Campaigns/components/CampaignActionBtn'
import CustomsWarningBanner from '@/components/MainPage/components/CustomsWarningBanner'
import ActiveWaitlistMainBlock from '@/components/Purchase/components/Wailists/ActiveWaitlistMainBlock'
import { useIntl } from 'react-intl'
import useSurveys from '@/components/Surveys/hooks/useSurveys'

const MainPage = () => {
  const intl = useIntl()
  const router = useRouter()

  useSurveys()
  const { campaigns, loading: campaignsLoading } = useCampaigns()
  const { orders, loading: ordersLoading } = useOrders()
  const slider = useSlider(campaigns.length)
  const currentCampaign = campaigns[slider.slide]

  useEffect(() => {
    Object.values(persistenceStateKeys).forEach((key) => localStorage.removeItem(key))
  }, [])

  const handleOrderSelect = useCallback(
    (id: IOrder['id']) => {
      router.push('/Orders/' + id)
    },
    [router]
  )

  const renderOrders = () => {
    if (!orders.length) return null

    return orders.map((o) => <OrderCard key={o.id} order={o} onSelect={handleOrderSelect} />)
  }

  const slides = useMemo(() => {
    return campaigns.map((c) => <CampaignTile campaign={c} />)
  }, [campaigns])

  const renderCampaigns = () => {
    if (!campaigns.length) return null

    return (
      <Slider
        slides={slides}
        slide={slider.slide}
        onPrev={slider.prevSlide}
        onNext={slider.nextSlide}
        onSelect={slider.onSelect}
      />
    )
  }

  const renderCampaignControls = () => {
    if (!currentCampaign) return null

    return (
      <>
        <CampaignActionBtn campaign={currentCampaign} />
        {currentCampaign.detailsUrl && (
          <Button theme={'grey'} onClick={() => domUtils.openLink(currentCampaign.detailsUrl)}>
            {currentCampaign.detailsUrlCaption || intl.formatMessage({ id: 'know_more' })}
          </Button>
        )}
      </>
    )
  }

  if (campaignsLoading || ordersLoading) {
    return <LoaderPage inscription={intl.formatMessage({ id: 'loading_orders_and_campaigns' })} />
  }

  return (
    <NewsProvider>
      <CustomsWarningBanner orders={orders} />
      <LogoLayout withContentPadding={false} headerStyle={{ marginBottom: 0 }}>
        <ActiveWaitlistMainBlock />
        <div className="flex-form" style={{ paddingTop: '25px' }}>
          {renderCampaigns()}
          <div className="form-footer sides-padding" style={{ paddingTop: 0 }}>
            {renderCampaignControls()}
          </div>
          <div style={{ marginTop: '40px' }} className="sides-padding">
            <MainNewsBlock />
          </div>
          {Boolean(orders.length) && (
            <h2 className="text-700 text--grey text-road sides-padding" style={{ paddingTop: '40px' }}>
              {intl.formatMessage({ id: 'footer.orders' })} ({orders.length})
            </h2>
          )}
          <div className="sides-padding flex-form">{renderOrders()}</div>
        </div>
      </LogoLayout>
    </NewsProvider>
  )
}

export default MainPage
