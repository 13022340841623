import { useRecoilState } from 'recoil'
import { useEffect } from 'react'
import surveysAtom from '../surveys.atom'
import surveysApi from '../surveys.api'
import useHttpLoader from '@/hooks/useHttpLoader'

const useSurveys = () => {
  const { wait, loading } = useHttpLoader()
  const [state, setState] = useRecoilState(surveysAtom)

  useEffect(() => {
    wait(surveysApi.list(), (resp) => {
      if (resp.status === 'success') {
        setState((prev) => ({ ...prev, items: resp.body.results }))
      }
    })
  }, [])

  return {
    surveys: state.items,
    loading,
  }
}

export default useSurveys
