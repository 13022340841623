import { IQuestionAnswer, ISurvey } from './surveys.types'
import { HTTPResponse, IPaginationResponse } from '@/types/http.types'
import http, { handleHttpError, handleHttpResponse } from '@/shared/http'

const list = (): Promise<HTTPResponse<IPaginationResponse<ISurvey[]>>> => {
  return http.get('/api/v1/surveys/').then(handleHttpResponse).catch(handleHttpError)
}

const processAndSubmit = async (surveyId: ISurvey['id'], data: IQuestionAnswer[]) => {
  const dataToSend: IQuestionAnswer[] = JSON.parse(JSON.stringify(data))
  for (let i = 0; i < dataToSend.length; ++i) {
    for (let j = 0; j < dataToSend[i].enteredAnswers.length; ++j) {
      if (dataToSend[i].enteredAnswers[j].answerResponseType === 'OPEN_ENDED_PRODUCT') {
        dataToSend[i].enteredAnswers[j].meta = {
          openEndedProduct: dataToSend[i].enteredAnswers[j].meta.openEndedProduct,
        }
      }
    }
  }

  return http
    .post('/api/v1/me/survey_responses/', { survey: surveyId, questions: dataToSend })
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const surveysApi = { list, processAndSubmit }
export default surveysApi
